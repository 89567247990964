import '@danfoss/etui-sm-xml';
import '../../../../../constants/session-storage-constants.js';
import '../../../../../constants/software-versions.js';
import '../../../../../constants/software-update-type.js';
import { UpdateSoftwareUnitStatus } from '../../../../../constants/software-update-unit-status.js';
import { SESSION_EXPIRY_CODE } from '../../../../../constants/index.js';
import '../utils/delay.js';
import './fetch-read-units.js';
import { fetchUpdateSoftwareUnitStatus } from './fetch-update-software-unit-status.js';
import { fetchLocalGraphicsUpdateSoftwareUnitStatus } from './fetch-local-graphics-update-software-unit-status.js';

/* eslint-disable no-await-in-loop */
const executeWithRestart = async _ref => {
  let {
    url,
    user,
    onTimespent,
    timeout,
    fn,
    isSm800 = false,
    isLocalGraphics = false,
    isBCMode = false
  } = _ref;
  const startTime = +new Date();
  let completed = false;
  let shouldLogout = false;
  const sm800Delay = isSm800 ? 5000 : 0;
  await fn();
  while (!completed) {
    let status = UpdateSoftwareUnitStatus.DOWN;
    try {
      if (isLocalGraphics) {
        status = await fetchLocalGraphicsUpdateSoftwareUnitStatus(url, user, sm800Delay, isBCMode);
      }
      status = await fetchUpdateSoftwareUnitStatus(url, user, sm800Delay);
    } catch (e) {
      if (+e.code === SESSION_EXPIRY_CODE) {
        status = UpdateSoftwareUnitStatus.UP;
        shouldLogout = true;
      } else throw e;
    }
    completed = status !== UpdateSoftwareUnitStatus.DOWN;
    const installTime = +new Date() - startTime;
    onTimespent(installTime);
    if (!completed && installTime > timeout) {
      throw Error('Timeout error');
    }
  }
  const restartStatus = {
    completed,
    shouldLogout
  };
  return restartStatus;
};

export { executeWithRestart };
