import '@danfoss/etui-sm-xml';
import '../../../../../_rollupPluginBabelHelpers-e1ae8e90.js';
import '@danfoss/etui-core';
import '../../../../../constants/session-storage-constants.js';
import '../../../../../constants/software-versions.js';
import '../../../../../constants/software-update-type.js';
import '../../../../../constants/software-update-unit-status.js';
import '../../../../../constants/index.js';
import '../../../../../utils/get-software-version-prefix.js';
import '../../../../../utils/get-is-matched-software-version.js';
import '../../../../../utils/get-language-code-by-name.js';
import '../../../../../utils/get-measurements-by-unit.js';
import '../../../../../utils/get-user-from-storage.js';
import '../../../../../utils/compare-software-versions.js';
import '../../../../../utils/get-version-from-filename.js';
import '../../../../../actions/authorize-units.js';
import '../../../../../actions/fetch-initial-unit.js';
import '../../../../../actions/fetch-metadata-files.js';
import '../../../../../actions/fetch-other-units.js';
import '../../../../../utils/get-is-misc-device.js';
import React from 'react';
import 'react-i18next';
import 'swr';
import 'use-deep-compare-effect';
import '../../../../../context/app/utils/get-xml-backend-ip.js';
import '../../../../../context/app/utils/get-xml-backend-protocol.js';
import '../../../../../context/app/utils/get-xml-proxy-config.js';
import '../../../../../context/app/utils/set-xml-proxy-config.js';
import '../../../../../context/app/app-context.js';
import '../../../../../context/app/hooks/use-app.js';
import '../../../../../context/app/hooks/use-xml-resource.js';
import '@danfoss/etui-sm-i18n';
import '../../../../../context/auth/utils/logout-clean-up.js';
import '../../../../../context/auth/utils/set-user-to-storage.js';
import '../../../../../context/auth/auth-context.js';
import '../../../../../context/auth/hooks/use-auth.js';
import 'styled-components';
import '../../../../../context/unit/utils/duplicate-session-info.js';
import '../../../../../context/unit/unit-actions.js';
import '../../../../../context/unit/unit-context.js';
import { useAsync } from '../../../../../hooks/use-async.js';
import 'match-sorter';
import '../context/SoftwareUpdateContext.js';
import '../context/hooks/use-multi-unit-update.js';
import '../../../../../use-update-type-values-02e53b90.js';
import '../utils/delay.js';
import '../actions/fetch-read-units.js';
import '../actions/fetch-update-software-unit-status.js';
import '../actions/fetch-local-graphics-update-software-unit-status.js';
import { executeWithRestart } from '../actions/execute-with-restart.js';
import { fetchEdfRollback } from '../actions/fetch-edf-rollback.js';
import { fetchRemoveEdfBackups } from '../actions/fetch-remove-edf-backup.js';
import { fetchUpdateEdfFiles } from '../actions/fetch-update-edf-files.js';
import { fetchUpdateSoftwareRestartApp } from '../actions/fetch-update-software-restart-app.js';
import '@danfoss/etui-sm';
import '@danfoss/etui-sm/actions';
import { useKeepSessionAlive } from './use-keep-session-alive.js';

function useAsyncInitiator(_ref) {
  let {
    trigger,
    fn
  } = _ref;
  const {
    execute,
    pending,
    value,
    error
  } = useAsync(fn, false);
  React.useEffect(() => {
    if (trigger) {
      execute();
    }
  }, [trigger]);
  return {
    pending,
    value,
    error
  };
}
function useEdfUpload(selectedEdfFiles, url, user, shouldStart, timeout) {
  useKeepSessionAlive();
  const {
    pending: uploading,
    value: uploadValue,
    error: uploadError
  } = useAsyncInitiator({
    trigger: shouldStart,
    fn: () => fetchUpdateEdfFiles(selectedEdfFiles, url, user)
  });
  const {
    pending: rollingBack,
    value: rollbackValue
  } = useAsyncInitiator({
    trigger: uploadError,
    fn: () => fetchEdfRollback(url, user)
  });
  const {
    pending: removingBackup,
    value: removeBackupValue,
    error: removeBackupError
  } = useAsyncInitiator({
    trigger: uploadValue || rollbackValue,
    fn: () => fetchRemoveEdfBackups(url, user)
  });
  const {
    pending: restarting,
    value: restartValue,
    error: restartError
  } = useAsyncInitiator({
    trigger: removeBackupValue,
    fn: () => executeWithRestart({
      url,
      user,
      timeout,
      onTimespent: () => {},
      fn: () => fetchUpdateSoftwareRestartApp(url, user)
    })
  });
  const error = restartError || removeBackupError || uploadError;
  const pending = uploading || removingBackup || restarting;
  const completed = !!(restartValue !== null && restartValue !== void 0 && restartValue.completed) && !error;
  return {
    uploading,
    removingBackup,
    restarting,
    error,
    pending,
    completed,
    rollingBack,
    shouldLogout: restartValue === null || restartValue === void 0 ? void 0 : restartValue.shouldLogout
  };
}

export { useEdfUpload };
